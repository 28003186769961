export default function GoogleColoredIcon({ className }: { className?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 46 46">
      <path
        fill="#4285f4"
        fillRule="evenodd"
        d="M43.72 23.49a24.8 24.8 0 0 0-.392-4.414H23v8.349h11.616c-.5 2.698-2.021 4.983-4.307 6.514v5.415h6.975c4.081-3.757 6.436-9.29 6.436-15.863z"
        clipRule="evenodd"
      />
      <path
        fill="#34a853"
        fillRule="evenodd"
        d="M23 44.583c5.828 0 10.713-1.932 14.284-5.229L30.31 33.94C28.377 35.235 25.905 36 23.001 36c-5.621 0-10.38-3.797-12.077-8.898h-7.21v5.592c3.55 7.054 10.85 11.89 19.287 11.89z"
        clipRule="evenodd"
      />
      <path
        fill="#fbbc05"
        fillRule="evenodd"
        d="M10.923 27.1c-.432-1.294-.677-2.677-.677-4.1s.245-2.806.677-4.1v-5.593h-7.21C2.25 16.221 1.416 19.517 1.416 23s.834 6.78 2.295 9.693l7.211-5.592z"
        clipRule="evenodd"
      />
      <path
        fill="#ea4335"
        fillRule="evenodd"
        d="M23 10c3.169 0 6.014 1.09 8.25 3.229l6.191-6.19C33.703 3.554 28.818 1.416 23 1.416c-8.437 0-15.736 4.836-19.288 11.89l7.211 5.592c1.697-5.1 6.457-8.897 12.077-8.897z"
        clipRule="evenodd"
      />
      <path fill="none" d="M1.417 1.417h43.166v43.166H1.417V1.417z" />
    </svg>
  );
}
