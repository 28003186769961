import { Form, useActionData, useFetcher, useLocation } from "@remix-run/react";
import { Collapse, CollapsePanel } from "antd-mobile/es/components/collapse/collapse";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gtagEvent } from "~/utils/analytics/gtag";
import { useRootData } from "~/utils/data/useRootData";
import { useAccountSelectorContext } from "../context/accountContext";
import ButtonPrimary from "../ui/buttons/ButtonPrimary";
import LoadingButton from "../ui/buttons/LoadingButton";
import EmailIcon from "../ui/icons/EmailIcon";
import ExclamationTriangleIcon from "../ui/icons/ExclamationTriangleIcon";
import FacebookIcon from "../ui/icons/FacebookIcon";
import GoogleColoredIcon from "../ui/icons/GoogleColoredIcon";

export default function Login() {
  const { t } = useTranslation();

  const [collapseKey, setCollapseKey] = useState([]);
  const { appConfiguration } = useRootData();

  // const [searchParams] = useSearchParams();

  const handleClickGoogle = () => {
    gtagEvent("login_attempt", {
      method: "google",
    });
  };
  const handleClickFacebook = () => {
    gtagEvent("login_attempt", {
      method: "facebook",
    });
  };

  //if fetcher response has account, set hasAccount to true

  const toggleCollapseKey = useCallback(() => {
    if (collapseKey.length === 0) {
      setCollapseKey(["email"]);
    } else {
      setCollapseKey([]);
    }
  }, [collapseKey]);

  return (
    <div className="mt-8 px-8">
      {/* <!-- SSO: START --> */}
      {(appConfiguration.auth.authMethods.google?.enabled || appConfiguration.auth.authMethods.facebook.enabled) && (
        <>
          <div>
            <div>
              <p className="text-sm font-medium text-gray-700">Sign in or Create with</p>

              <div className="mt-4 grid gap-4 ">
                {/* Google */}
                {appConfiguration.auth.authMethods.google.enabled && (
                  <Form action="/oauth/google" method="post">
                    <ButtonPrimary
                      type="submit"
                      onClick={handleClickGoogle}
                      className="w-full justify-center rounded-md border-gray-300 px-4 py-2 text-sm font-medium text-gray-500 hover:opacity-90"
                    >
                      <GoogleColoredIcon className="h-5 w-5 text-white" /> <span className="w-20">{t("auth.google.button")}</span>
                    </ButtonPrimary>
                  </Form>
                )}
                {/* Google: End */}
                {/* Facebook */}
                {appConfiguration.auth.authMethods.facebook.enabled && (
                  <Form action="/auth/facebook" method="post">
                    <ButtonPrimary
                      type="submit"
                      onClick={handleClickFacebook}
                      className="w-full justify-center rounded-md border-gray-300 px-4 py-2 text-sm font-medium text-gray-500 hover:opacity-90"
                    >
                      <FacebookIcon className="h-5 w-5 text-white" />
                      <span className="w-20">{t("auth.facebook.button")}</span>
                    </ButtonPrimary>
                  </Form>
                )}
                {/* Facebook: End */}
                {/* GitHub */}
                {/* {appConfiguration.auth.authMethods.github.enabled && (
                  <div>
                    <ButtonPrimary
                      className="w-full justify-center rounded-md border-gray-300 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                      to={appConfiguration.auth.authMethods.github.authorizationURL}
                      isExternal
                    >
                      <GitHubIcon className="h-5 w-5 text-white" /> <span className="w-20">{t("auth.github.button")}</span>
                    </ButtonPrimary>
                  </div>
                )} */}

                {/* GitHub: End */}
                {/* Email */}
                {appConfiguration.auth.authMethods.emailPassword.enabled && (
                  <div>
                    <ButtonPrimary
                      className="w-full justify-center rounded-md border-gray-300 px-4 py-2 text-sm font-medium text-gray-500 "
                      onClick={toggleCollapseKey}
                    // to={appConfiguration.auth.authMethods.github.authorizationURL}
                    // isExternal
                    >
                      <EmailIcon className="h-5 w-5 text-white" />
                      <span className="w-20">{t("auth.email.button")}</span>
                    </ButtonPrimary>
                  </div>
                )}
                {/* Email: End */}
              </div>
            </div>

            {/* <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">Or</span>
              </div>
            </div> */}
          </div>
        </>
      )}
      {/* <!-- SSO: END --> */}
      {appConfiguration.auth.authMethods.emailPassword.enabled && <LoginEmailForm collapseKey={collapseKey} />}
    </div>
  );
}

export function LoginEmailForm({ collapseKey, showLogin = true, recipeId }) {
  const { t } = useTranslation();

  const actionData = useActionData();
  const setAccountForgotPassword = useAccountSelectorContext((state) => state.setAccountForgotPassword);
  const btnClassNames = clsx(
    "transition-colors flex w-full justify-center rounded-md border px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm"
  );
  const primaryActionClassNames = clsx("bg-theme-600 border-transparent hover:bg-theme-700 text-white focus:ring-theme-500 dark:bg-gray-900");
  const secondaryActionClassNames = clsx("!border-gray-300 bg-white !hover:border-accent-300 !text-inherit hover:text-accent-900 focus:ring-accent-500");
  const location = useLocation();
  const formFetcher = useFetcher();

  const { csrf, userSession } = useRootData();
  const fetcher = useFetcher();
  const [hasAccount, setHasAccount] = useState(userSession?.hasLoggedIn);
  const [hasSub, setHasSub] = useState(false);
  useEffect(() => {
    if (fetcher.data?.hasAccount !== undefined) {
      setHasAccount(fetcher.data?.hasAccount);
      setHasSub(fetcher.data?.hasSub);
    }
  }, [fetcher.data]);

  const handleCheckEmail = async (e) => {
    const email = e.target.value;
    const form = new FormData();
    form.append("email", email);
    fetcher.submit(form, {
      method: "POST",
      action: "api/auth/checkUser",
    });
  };

  const handleClickEmail = () => {
    gtagEvent("login_attempt", {
      method: "email",
    });
  };

  return (
    <Collapse className="p-0-content border-none" activeKey={collapseKey}>
      <CollapsePanel title={""} key="email" arrow={false}>
        <formFetcher.Form method="post" className="space-y-6" action="/login">
          <input type="hidden" name="action" value="with-email" />
          {/* <input type="hidden" name="redirectTo" value={location.pathname} /> */}
          <input type="hidden" hidden readOnly name="csrf" value={csrf} />
          <input type="hidden" hidden readOnly name="hasAccount" value={hasAccount} />
          <input type="hidden" hidden readOnly name="hasSub" value={hasSub} />
          <input type="hidden" hidden readOnly name="recipeId" value={recipeId} />

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              {t("account.shared.email")}
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:z-10 focus:border-theme-500 focus:outline-none focus:ring-theme-500 dark:border-gray-700 dark:bg-gray-900 dark:text-slate-200 sm:text-sm"
                placeholder="email@address.com"
                onBlur={handleCheckEmail}
              // onFocus={handleClearSub}
              />
              {actionData?.fieldErrors?.email ? (
                <p className="py-2 text-xs text-rose-500" role="alert" id="email-error">
                  {actionData.fieldErrors.email}
                </p>
              ) : null}
            </div>
          </div>

          <div className="space-y-1">
            <label
              htmlFor={userSession.hasLoggedIn ? "current-password" : "new-password"}
              className="block text-sm font-medium text-gray-700 dark:text-gray-400"
            >
              {t("account.shared.password")}
            </label>
            <div className="mt-1">
              {hasAccount ? (
                <input
                  id={"current-password"}
                  name={"current-password"}
                  type="password"
                  autoComplete={"current-password"}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:z-10 focus:border-theme-500 focus:outline-none focus:ring-theme-500 dark:border-gray-700 dark:bg-gray-900 dark:text-slate-200 sm:text-sm"
                  placeholder="************"
                />
              ) : (
                <input
                  id={"new-password"}
                  name={"new-password"}
                  type="password"
                  autoComplete={"new-password"}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:z-10 focus:border-theme-500 focus:outline-none focus:ring-theme-500 dark:border-gray-700 dark:bg-gray-900 dark:text-slate-200 sm:text-sm"
                  placeholder="************"
                />
              )}

              {actionData?.fieldErrors?.password ? (
                <p className="py-2 text-xs text-rose-500" role="alert" id="password-error">
                  {actionData.fieldErrors.password}
                </p>
              ) : null}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-theme-600 focus:ring-theme-500" />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            {/* TODO: forgot password */}
            <div className="text-sm">
              <div className="font-medium text-theme-600 hover:text-theme-500 hover:underline" onClick={setAccountForgotPassword}>
                {t("account.login.forgot")}
              </div>
            </div>
          </div>

          <div className="flex space-x-2">
            <div className="flex-1">
              <LoadingButton
                className={clsx(btnClassNames, !showLogin || hasAccount === false ? primaryActionClassNames : secondaryActionClassNames)}
                type="submit"
                actionName="with-email"
                onClick={handleClickEmail}
              // disabled={hasAccount === true}
              >
                {"Sign-Up"}
              </LoadingButton>
            </div>
            {showLogin && (
              <div className="flex-1">
                <LoadingButton
                  className={clsx(btnClassNames, hasAccount === true ? primaryActionClassNames : secondaryActionClassNames)}
                  type="submit"
                  actionName="with-email"
                  onClick={handleClickEmail}
                // disabled={hasAccount === false}
                >
                  {t("account.login.button")}
                </LoadingButton>
              </div>
            )}
          </div>
          <div id="form-error-message">
            {actionData?.error ? (
              <div className="flex items-center justify-center space-x-2 text-sm text-red-500 dark:text-red-300" role="alert">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <div>{actionData.error}</div>
              </div>
            ) : null}
          </div>
        </formFetcher.Form>
      </CollapsePanel>
    </Collapse>
  );
}
